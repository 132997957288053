import React from "react";
import Layout from "@layouts/RecrutamentoLayout";

const RecrutamentoPage = () => {
  return (
      <Layout title="Atração e seleção de talentos" servico="RECRUTAMENTO"> 
        <div className="text-xl flex flex-col text-gray-700 space-y-4 mr-10">
            <div className="w-full flex flex-row my-10">        
                <img className="h-14" src="/images/logo_escura.png" alt="Shelfin"/>
            </div> 
            <p className="font-medium text-gray-700 text-3xl mb-6">
                Com processo de <b>Recrutamento e Seleção</b> especializado, encontramos profissionais qualificados e no perfil solicitado pela empresa.
            </p>
            <p>
               Por meio de levantamento detalhado, identificamos os principais valores e aspectos, modelos de gestão, clima organizacional e seus objetivos. Assim conseguimos aproximar o perfil do candidato ao perfil de sua empresa. 
            </p>
            <p>
                <b>Nosso processo de seleção é estratégico</b> e agrega real valor ao negócio. Pois encontramos o melhor perfil profissional de acordo com cada cargo, considerando todos os aspectos técnicos e comportamentais, alinhados a aplicação de testes, avaliações psicológicas e comportamentais.
            </p>
        </div> 
      </Layout>
  )
}

export default RecrutamentoPage
